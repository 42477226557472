/*
 * Game backend code.
 * Code gets executed on the Onchain through GameCaster.
 *
 * Each script consists of and array of functions,
 * where the the last function return an JSUpdate to update the game state,
 * and the other functions can return JSFetch to fetch data from the game state.
 *
 * They get executed in order, and pass their results to the next function.
 * This way you can fetch data, and make decisions based on that data.
 *
 * For example you can fetch the player's current lives and check if they can start a new level
 *
 * IMPORTANT: The code cannot contain any | symbols.
 * This is a limitation when posting the code on-chain, as the | is used as delimiter symbol.
 * We are working on a fix for this.
 */
export const flipCardCode = [
    function main(setup, input, _) {
        const [key] = input.value.split('#');
        return [{ key, wallet: setup.wallet }];
    },
    function main(setup, input, context) {
        if (context.function0.length === 0) {
            const possibleCards = ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9'];
            const s = Math.abs(setup.seed);
            const randomCard = possibleCards[s % possibleCards.length];
            const [key, card] = input.value.split('#');
            const win = card === randomCard;
            return {
                prize: win ? Math.max((s % 100) / 1000, 0.01) : 0,
                updates: [
                    {
                        key,
                        wallet: setup.wallet,
                        value: JSON.stringify({
                            win,
                            selected: card,
                            winner: randomCard,
                        }),
                    },
                ],
            };
        }
        else {
            return {
                updates: [],
            };
        }
    },
];
export const useItemCode = [
    function main(setup, input, _) {
        return {
            updates: Array(input.amount)
                .fill(0)
                .map((_, i) => ({
                wallet: setup.wallet,
                key: 'item-' + i + '-' + input.itemName,
                value: setup.block_height.toString(),
            })),
        };
    },
];
