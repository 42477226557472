import { flipCardCode, useItemCode } from './functions.js';
export const backendSetup = {
    backendCode: [
        {
            type: 'state', // or item
            state: 'flip',
            callback: JSON.stringify(flipCardCode.map(c => c.toString())),
        },
    ],
    items: [
        {
            name: 'sword',
            description: 'a sword to fight with',
            price: 1200,
            callback: JSON.stringify(useItemCode.map(c => c.toString())),
            frontendEvent: (gameIndex) => {
                return gameIndex;
            },
            devImage: 'https://placehold.co/200x200/d35400/white?text=Sword',
        },
        {
            name: 'shield',
            description: 'defend yourself',
            price: 1500,
            callback: JSON.stringify(useItemCode.map(c => c.toString())),
            frontendEvent: (gameIndex) => {
                return gameIndex;
            },
            devImage: 'https://placehold.co/200x200/1abc9c/white?text=Shield',
        },
    ],
};
