"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cardName = void 0;
exports.cardName = {
    c1: 'Kitchen Cat',
    c2: 'Color Mail Cat',
    c3: 'Coffee Cat',
    c4: 'Black & White Mail Cat',
    c5: 'Card Player Cat',
    c6: 'Big Firefighter Cat',
    c7: 'Adventurer Cat',
    c8: 'Business Cat',
    c9: 'Small Firefighter Cat',
};
