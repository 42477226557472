var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
setInterval(() => {
    LocalBackend.block += 1;
    LocalBackend.seed += 1;
}, 1000);
/**
 * This class implements a local-backend that emulates the backend for the game.
 * It generate a emulation of the Blockchain + PaimaEngine for the frontend to run.
 */
export class LocalBackend {
    constructor(paima) {
        this.paima = paima;
    }
    getDatabaseRaw() {
        return {
            keyValue: LocalBackend.keyValue,
            keyWalletValue: LocalBackend.keyWalletValue,
            jackpot: LocalBackend.jackpot,
            block: LocalBackend.block,
            seed: LocalBackend.seed,
            inventory: LocalBackend.inventory,
        };
    }
    runCustomCode(code) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`RUNNING
      =====================
      ${code}
      =====================
      `);
            const result = eval(code);
            console.log(`RESULT
      ${JSON.stringify(result)}
      =====================
      `);
            return result;
        });
    }
    runProgram(setup, input, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            {
                const ops = { prize: 0, walletKeyValues: [], keyValue: [] };
                const src = JSON.parse(callback.javascript);
                const lastIndex = src.length - 1;
                let currentIndex = 0;
                const context = {};
                for (const s of src) {
                    const finalSrc = `
        ${s}
        ;
        const context = ${JSON.stringify(context)};
        const input = ${JSON.stringify(input)}; 
        const setup = ${JSON.stringify(setup)};
        main(setup, input, context);
      `;
                    const result = yield this.runCustomCode(finalSrc);
                    if (currentIndex === lastIndex) {
                        const up = result;
                        if (up.prize && up.prize > 0 && up.prize < 1) {
                            ops.prize = up.prize;
                        }
                        up.updates.forEach(u => {
                            if (u.wallet) {
                                ops.walletKeyValues.push({ wallet: u.wallet, key: u.key, value: u.value });
                            }
                            else {
                                ops.keyValue.push({ key: u.key, value: u.value });
                            }
                        });
                    }
                    else {
                        const fetchOps = result;
                        for (const fetchOp of fetchOps) {
                            if (fetchOp.key && fetchOp.wallet) {
                                const name = 'function' + currentIndex;
                                const data = this.getWalletKeyValue(fetchOp.wallet, fetchOp.key);
                                if (!context[name])
                                    context[name] = [];
                                if (data == null) {
                                    // do nothing
                                }
                                else {
                                    context[name].push({ key: fetchOp.key, wallet: fetchOp.wallet, value: data });
                                }
                            }
                            else if (fetchOp.key) {
                                const name = 'function' + currentIndex;
                                const data = this.getKeyValue(fetchOp.key);
                                if (!context[name])
                                    context[name] = [];
                                if (data == null) {
                                    // do nothing
                                }
                                else {
                                    context[name].push({ key: fetchOp.key, value: data });
                                }
                            }
                        }
                    }
                    currentIndex += 1;
                }
                ops.walletKeyValues.forEach(({ wallet, key, value }) => {
                    this.setWalletKeyValue(wallet, key, value);
                });
                ops.keyValue.forEach(({ key, value }) => {
                    this.setKeyValue(key, value);
                });
                // emit event
                window.parent.postMessage({
                    target: 'iframe-game',
                    action: 'event',
                    payload: LocalBackend.block,
                }, '*');
                return { prize: ops.prize };
            }
        });
    }
    getKeyValue(key) {
        return LocalBackend.keyValue.get(key);
    }
    getWalletKeyValue(wallet, key) {
        var _a;
        return (_a = LocalBackend.keyWalletValue.get(wallet)) === null || _a === void 0 ? void 0 : _a.get(key);
    }
    setKeyValue(key, value) {
        LocalBackend.keyValue.set(key, value);
    }
    setWalletKeyValue(wallet, key, value) {
        var _a;
        if (!LocalBackend.keyWalletValue.has(wallet)) {
            LocalBackend.keyWalletValue.set(wallet, new Map());
        }
        (_a = LocalBackend.keyWalletValue.get(wallet)) === null || _a === void 0 ? void 0 : _a.set(key, value);
    }
    addToJackpot(amount) {
        LocalBackend.jackpot += amount;
    }
    getJackpot() {
        return LocalBackend.jackpot;
    }
    buyItem(wallet, item) {
        var _a;
        if (!wallet) {
            console.log('no wallet');
            return;
        }
        const itemData = this.paima.backendSetup.items.find(i => i.name === item);
        if (itemData) {
            if (!LocalBackend.inventory.has(wallet)) {
                LocalBackend.inventory.set(wallet, new Map());
            }
            const inventory = LocalBackend.inventory.get(wallet);
            const itemCount = (_a = inventory === null || inventory === void 0 ? void 0 : inventory.get(item)) !== null && _a !== void 0 ? _a : 0;
            inventory === null || inventory === void 0 ? void 0 : inventory.set(item, itemCount + 1);
            this.addToJackpot(Math.round(itemData.price * 0.5));
            window.parent.postMessage({
                target: 'iframe-game',
                action: 'game_action',
                payload: {
                    user: wallet,
                    activityType: 'buy',
                    amount: 1,
                    erc20_index: String(this.paima.gameParams.erc20address),
                },
            }, '*');
        }
    }
    useItem(wallet, item, amount, metadata, setup) {
        var _a;
        if (!wallet) {
            console.log('no wallet');
            return;
        }
        const itemData = this.paima.backendSetup.items.find(i => i.name === item);
        if (itemData) {
            if (!LocalBackend.inventory.has(wallet)) {
                LocalBackend.inventory.set(wallet, new Map());
            }
            const inventory = LocalBackend.inventory.get(wallet);
            const itemCount = (_a = inventory === null || inventory === void 0 ? void 0 : inventory.get(item)) !== null && _a !== void 0 ? _a : 0;
            if (itemCount >= amount) {
                inventory === null || inventory === void 0 ? void 0 : inventory.set(item, itemCount - amount);
                const input = {
                    input: 'useItem',
                    erc_index: setup.erc_index,
                    itemName: item,
                    amount,
                    metadata,
                };
                setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                    yield this.runProgram(setup, input, { javascript: itemData.callback });
                    window.parent.postMessage({
                        target: 'iframe-game',
                        action: 'game_action',
                        payload: {
                            user: wallet,
                            activityType: 'use',
                            amount: 1,
                            erc20_index: String(this.paima.gameParams.erc20address),
                        },
                    }, '*');
                }), 0);
            }
        }
    }
    getInventoryItemCount(wallet, item) {
        var _a, _b;
        return (_b = (_a = LocalBackend.inventory.get(wallet)) === null || _a === void 0 ? void 0 : _a.get(item)) !== null && _b !== void 0 ? _b : 0;
    }
}
LocalBackend.keyValue = new Map();
LocalBackend.keyWalletValue = new Map();
LocalBackend.jackpot = 0;
LocalBackend.block = 0;
LocalBackend.seed = 0;
LocalBackend.inventory = new Map();
