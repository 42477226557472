/**
 * This is developer only overlay to purchase items and view the local-database. This class only works with the local-backend
 * */
export class DevOverlay {
    constructor(localPaima) {
        this.localPaima = localPaima;
        this.isItemsOpen = false;
        this.isDatabaseOpen = false;
        this.createButtons();
        this.createItemsOverlay();
        this.createDatabaseOverlay();
    }
    createButtons() {
        this.createButton('Items (dev only)', () => this.toggleOverlay('items'), '120px');
        this.createButton('Show Local Database (dev only)', () => this.toggleOverlay('database'), '20px');
    }
    createButton(text, onClick, bottom) {
        const button = document.createElement('button');
        button.textContent = text;
        button.style.cssText = `
      position: fixed;
      bottom: ${bottom};
      right: 20px;
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    `;
        button.addEventListener('click', onClick);
        document.body.appendChild(button);
    }
    createItemsOverlay() {
        this.itemsOverlayElement = document.createElement('div');
        this.itemsOverlayElement.style.cssText = `
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1000;
    `;
        const content = document.createElement('div');
        content.style.cssText = `
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      min-width: 400px;
      max-height: 80%;
      overflow-y: auto;
    `;
        const closeButton = document.createElement('button');
        closeButton.textContent = 'Close';
        closeButton.style.cssText = `
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px 10px;
      background-color: #f44336;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;
    `;
        closeButton.addEventListener('click', () => this.toggleOverlay('items'));
        content.appendChild(closeButton);
        this.itemList = this.createItemList();
        content.appendChild(this.itemList);
        this.itemsOverlayElement.appendChild(content);
        document.body.appendChild(this.itemsOverlayElement);
    }
    createDatabaseOverlay() {
        this.databaseOverlayElement = this.createOverlayElement();
        const content = this.createOverlayContent();
        const closeButton = this.createCloseButton(() => this.toggleOverlay('database'));
        content.appendChild(closeButton);
        const databaseContent = document.createElement('pre');
        databaseContent.style.cssText = `
      white-space: pre-wrap;
      word-wrap: break-word;
    `;
        content.appendChild(databaseContent);
        this.databaseOverlayElement.appendChild(content);
        document.body.appendChild(this.databaseOverlayElement);
    }
    createItemList() {
        const list = document.createElement('ul');
        list.style.cssText = `
      list-style-type: none;
      padding: 0;
    `;
        this.localPaima.backendSetup.items.forEach(item => {
            const listItem = this.createItemListItem(item);
            list.appendChild(listItem);
        });
        return list;
    }
    createItemListItem(item) {
        const listItem = document.createElement('li');
        listItem.style.cssText = `
      margin-bottom: 20px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
    `;
        const itemName = document.createElement('h3');
        itemName.textContent = item.name;
        itemName.style.margin = '0 0 10px 0';
        const itemDescription = document.createElement('p');
        itemDescription.textContent = item.description;
        itemDescription.style.margin = '0 0 10px 0';
        const itemPrice = document.createElement('p');
        itemPrice.textContent = `Price: ${item.price}`;
        itemPrice.style.margin = '0 0 10px 0';
        const itemCount = document.createElement('p');
        itemCount.id = `item-count-${item.name}`;
        itemCount.style.margin = '0 0 10px 0';
        const buyButton = this.createActionButton('Buy', () => this.handleItemAction(buyButton, () => this.localPaima.localBuyItem(item.name), item.name));
        listItem.appendChild(itemName);
        listItem.appendChild(itemDescription);
        listItem.appendChild(itemPrice);
        listItem.appendChild(itemCount);
        listItem.appendChild(buyButton);
        return listItem;
    }
    updateItemList() {
        this.localPaima.backendSetup.items.forEach(item => {
            this.updateItemCount(item.name);
        });
    }
    updateItemCount(itemName) {
        const countElement = document.getElementById(`item-count-${itemName}`);
        if (countElement) {
            const count = this.localPaima.localBackend.getInventoryItemCount(this.localPaima.gameParams.wallet || '0x0', itemName);
            countElement.textContent = `Owned: ${count}`;
        }
    }
    createActionButton(text, action) {
        const button = document.createElement('button');
        button.textContent = text;
        button.style.cssText = `
      margin-right: 10px;
      padding: 5px 10px;
      background-color: #008CBA;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      min-width: 80px;
    `;
        button.addEventListener('click', action);
        return button;
    }
    handleItemAction(button, action, itemName, closeOverlay = false) {
        if (button.classList.contains('loading'))
            return;
        button.classList.add('loading');
        const originalText = button.textContent || '';
        this.showLoader(button, originalText);
        action();
        setTimeout(() => {
            this.hideLoader(button, originalText);
            this.updateItemCount(itemName);
            if (closeOverlay) {
                this.toggleOverlay('items');
            }
        }, 1250);
    }
    showLoader(button, originalText) {
        const loader = document.createElement('div');
        loader.className = 'loader';
        loader.style.cssText = `
      width: 20px;
      height: 20px;
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      animation: spin 1s linear infinite;
    `;
        button.insertBefore(loader, button.firstChild);
        const keyframes = `
      @keyframes spin {
        0% { transform: translateY(-50%) rotate(0deg); }
        100% { transform: translateY(-50%) rotate(360deg); }
      }
    `;
        const style = document.createElement('style');
        style.textContent = keyframes;
        document.head.appendChild(style);
        button.style.paddingLeft = '40px';
        button.style.color = 'rgba(255, 255, 255, 0.5)';
    }
    hideLoader(button, originalText) {
        const loader = button.querySelector('.loader');
        if (loader) {
            loader.remove();
        }
        button.style.paddingLeft = '10px';
        button.style.color = 'white';
        button.classList.remove('loading');
    }
    toggleOverlay(type) {
        if (type === 'items') {
            this.isItemsOpen = !this.isItemsOpen;
            this.itemsOverlayElement.style.display = this.isItemsOpen ? 'block' : 'none';
            if (this.isItemsOpen) {
                this.updateItemList();
            }
        }
        else {
            this.isDatabaseOpen = !this.isDatabaseOpen;
            this.databaseOverlayElement.style.display = this.isDatabaseOpen ? 'block' : 'none';
            if (this.isDatabaseOpen) {
                this.updateDatabaseContent();
            }
        }
    }
    updateDatabaseContent() {
        const databaseContent = this.databaseOverlayElement.querySelector('pre');
        if (databaseContent) {
            const rawDatabase = this.localPaima.localBackend.getDatabaseRaw();
            const queryParams = this.localPaima.gameParams;
            databaseContent.textContent = JSON.stringify({ queryParams, rawDatabase }, (key, value) => {
                if (value instanceof Map) {
                    return Object.fromEntries(value);
                }
                return value;
            }, 2);
        }
    }
    createOverlayElement() {
        const overlay = document.createElement('div');
        overlay.style.cssText = `
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1000;
    `;
        return overlay;
    }
    createOverlayContent() {
        const content = document.createElement('div');
        content.style.cssText = `
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      min-width: 400px;
      max-height: 80%;
      overflow-y: auto;
    `;
        return content;
    }
    createCloseButton(onClose) {
        const closeButton = document.createElement('button');
        closeButton.textContent = 'Close';
        closeButton.style.cssText = `
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px 10px;
      background-color: #f44336;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;
    `;
        closeButton.addEventListener('click', onClose);
        return closeButton;
    }
}
