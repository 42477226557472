export class GameParams {
    constructor(isLocalBackend) {
        var _a, _b, _c, _d;
        const urlParams = new URLSearchParams(window.location.search);
        this.erc20symbol = (_a = urlParams.get('erc20symbol')) !== null && _a !== void 0 ? _a : (isLocalBackend ? 'LOCAL' : 'error');
        this.erc20index = (_b = urlParams.get('erc20index')) !== null && _b !== void 0 ? _b : (isLocalBackend ? '42000' : 'error');
        this.erc20address =
            (_c = urlParams.get('erc20address')) !== null && _c !== void 0 ? _c : (isLocalBackend ? '0xdF3e18d64BC6A983f673Ab319CCaE4f1a57C7097' : 'error');
        this.wallet =
            (_d = urlParams.get('wallet')) !== null && _d !== void 0 ? _d : (isLocalBackend ? '0xBcd4042DE499D14e55001CcbB24a551F3b954096' : 'error');
        if (this.erc20symbol === 'error' ||
            this.erc20index === 'error' ||
            this.erc20address === 'error' ||
            this.wallet === 'error') {
            throw new Error('Invalid query parameters');
        }
    }
}
