"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIRemote = exports.APILocalDev = void 0;
const gamecaster_lib_1 = require("@gamecaster/gamecaster-lib");
// This class extends the dev/local version of GameCaster
// and adds helper methods to get the data
class APILocalDev extends gamecaster_lib_1.GameCasterLocalDev {
    getRound(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.getWalletKeyValue([key]);
            if (data == null)
                return null;
            return data[0].value;
        });
    }
}
exports.APILocalDev = APILocalDev;
// This class extends the prod/remote version of GameCaster
// and adds helper methods to get the data
class APIRemote extends gamecaster_lib_1.GameCasterRemote {
    getRound(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.getWalletKeyValue([key]);
            if (data == null)
                return null;
            return data[0].value;
        });
    }
}
exports.APIRemote = APIRemote;
